@import "src/main.scss";

.navbar{
    padding: 0.2rem 4rem!important;
    background-color: rgba(255, 255, 255, 0.404) !important;
    z-index: 9 !important;
    // border-bottom: $primary-color solid 3px !important;
    transition: all .25s ease;
    width: 100vw !important;
    // filter: drop-shadow(0px 2px 10px #00000025) !important;
}

.navbar_scroll{
    display: none !important;
    padding: 0rem 0rem!important;
    height: 6rem !important;
    background-color: #fff !important;
    z-index: 1 !important;

    &.home-logo{
        width: 4rem !important;
    }
}

.home-logo{
    width: 6rem !important;
    padding: 0.5rem 0rem!important;
    cursor: pointer;
    transition: all .25s ease;
}

.menu-logo{
    width: 5rem !important;
    cursor: pointer !important;
}

@media only screen and(max-width:768px) {
    .navbar{
        padding: 0rem 1rem!important;
        background-color: rgb(255, 255, 255) !important;
        width: 100vw !important;
    }

    .home-logo{
        width: 5rem !important;
    }

    .menu-logo{
        width: 4rem !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .navbar{
        padding: 1rem 2rem!important;
        border-bottom: none !important;
        filter: drop-shadow(0px 2px 10px #fbae4259) !important;
    }
    .home-logo{
        width: 6rem !important;
        padding: 0.5rem 0rem!important;
    }
}