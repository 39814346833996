@import "src/main.scss";



.table{
    margin-top: 2rem !important;
    height: 100% !important;
    width: 80vw !important;

    & th{
        background-color: #f6f6f6 !important;
    }
}

.enquiry-page{
    height: 100% !important;
}

.enq-name{
    border-bottom: 6px $primary-color solid !important;
}

tbody tr{
    background-color: white !important;
}

.export-btn{
    background-color: $secondary-color !important;
    border: 1px solid $secondary-color !important;
    width: 10rem !important;
    padding: 0.8rem 0;
    color: white;
    transition: 0.3s ease;

    &:hover{
        background-color: white !important;
        color:$secondary-color !important;

    }
}

.pagination{
    display: flex;
    justify-content: start;
    margin-left: -3rem !important;
}

.paginate-list{
    list-style: none;
    display: flex;
}

.paginate-item{
    // margin-right: 1rem !important;
    font-size: 1.3rem !important;
    color: $secondary-color !important;
    border: 1px solid #c0c0c0 !important;
    padding: 0.5rem 1rem;
    transition: .3s ease;
    cursor: pointer !important;

    &:hover{
        background-color: $secondary-color !important;
        border: 1px solid $secondary-color !important;
        color: $primary-color !important;
    }
}

// .table .tbody .table-r{
//     background-color: transparent !important;
// }

@media only screen and(max-width:768px) {

    .pagination{
        display: flex;
        justify-content: start;
        margin-left: 0rem !important;
        margin-bottom: 3rem !important;
    }
    
}
