@import "src/main.scss";

.works-area{
    height: 100% !important;
    
    position: relative !important;
    background-color: white !important;
    padding:10rem 0rem !important;
    // border: 1rem $primary-color solid;
}

.works-area .box-btn .bi.bi-search{
    color: $primary-color !important;
    margin-left: 0.5rem !important;
}

.event-works{
    position: relative;
    
    z-index: 3 !important;
}


.works-area .box-btn{
    margin-top: 1rem !important;
    background-color: $secondary-color !important;
    color: white !important;
    
    filter: drop-shadow(0px 2px 10px #fbae42ad) !important;
}

.works-area .box-btn:hover{
    background-color: transparent !important;
    color: $secondary-color !important;
}

.works-area .box-btn:hover .bi.bi-search{
    color: $secondary-color !important;
    margin-left: 0.5rem !important;
}

.event-row{
    background-color: #f8f8f8 !important;
    padding: 2rem !important;
}

.css-1b3lipl::before {
    background-color: #ededed !important;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
}

.css-692fgp{
    display: none !important;
}

.css-vcfpfv {
    background-color: $primary-color !important;
    border-radius: 50% !important;
    outline: 10px solid white;
    display: block;
    margin: 0.5em 0.5em 0.5em -0.5em;
    position: absolute;
    top: 0;
    left: 1.8em !important;
    width: 1.5em !important;
    height: 1.5em !important;
}

.works-area .css-1jv1kel{
    padding: 2em 2em 0 2em;
    position: relative;
    top: -1.875em;
    left: 2em;
    width: 95%;
    height: 100% !important;
}

// .history-row{
//     background-color: $primary-color !important;
// }

.timeline-row{
    margin-top: 10rem !important;
}

.our-work-img{
    width: 100vw !important;
    height: 100% !important;
}

.css-o1utbh p{
    font-family: $font-family !important;
    font-size: $para-size;
    font-weight: $regular-weight !important;
    line-height: 1.4;
    letter-spacing: 0;
}

.history-row{
    margin-top:-4rem !important ;
}


.work-info-col{
    height: auto !important;
    display: flex;
    justify-content: center;
    align-items: flex-start !important;
    flex-direction: column !important;
}

.work-header{
    font-family: $font-family !important;
    font-size: $sub-header-size !important;
    font-weight: $super-header-weight !important;
    text-transform: uppercase !important;
    letter-spacing: 0 !important;
}

.hero-works-para{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 4; /* number of lines to show */
   -webkit-box-orient: vertical;
}

.category-work{
    font-size: $misc-size !important;
    font-style: italic !important;
}

.margin-timeline{
    margin-bottom: 7rem !important;
}

.bi.bi-search{
    
    transition: 0.3s ease-in-out !important;
}

.box-btn:hover .bi.bi-search {
    color: white !important;
}

@media only screen and(max-width:768px) {
    .works-area{
        padding:5rem 1rem 3rem 1rem!important;
        // border: 0.2rem $primary-color solid;
    }

    .event-row{
        background-color: white !important;
        padding: 0rem !important;
    }

    .our-work-img{
        width: 10rem !important;
        height: 10rem !important;
    }

    .img-2-t{
        margin-top: 2rem !important;
    }

    .timeline-row{
        margin-top: 5rem !important;
        padding: 0rem !important;
    }

    .work-header{
        font-family: $font-family !important;
        font-size: $sub-header-size-mobile !important;
        font-weight: $super-header-weight !important;
        text-transform: none !important;
        letter-spacing: 0 !important;
        line-height: 1.2 !important;
    }

    .work-info-col{
        margin-top: 1rem !important;
    }

    
    .history-row{
        margin-top:-2rem !important ;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    
    .margin-m-t{
        margin-top: -1rem !important;
    }

    .our-work-img{
        width: 20rem !important;
        height: 20rem !important;
    }

    .m-t{
        margin-top: 2rem !important;
    }
    
    .history-row{
        margin-top:-2rem !important ;
    }

    work-header{
        font-family: $font-family !important;
        font-size: $sub-header-size-mobile !important;
        font-weight: $super-header-weight !important;
        text-transform: none !important;
        letter-spacing: 0 !important;
        line-height: 1.2 !important;
    }
}