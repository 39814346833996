@import "src/main.scss";

.featured-area{
    height: 100% !important;
    
    position: relative !important;
    background-color: #ededed !important;
    padding:4rem 0rem !important;
}

.featured-box{
    border:3px solid #3d3d3a !important;
    padding:4rem 0rem !important;
}

.featured-info .edit-btn-2{
    font-family: $font-family !important;
    letter-spacing: 0 !important;
    display: inline-block;
    position: relative;
    font-size: $para-size !important;
    font-weight: 400 !important;
    text-align: left !important;
    text-decoration: none;
    border-bottom: 3px solid $secondary-color !important;  
    overflow: hidden;
    color: $secondary-color !important;
    background-color: transparent !important;

    &::after{
        display:block;
        content: '';
        width: 5.5rem !important;
        margin-top: -2px;
        border-bottom: 4px solid $primary-color !important;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%;
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$secondary-color !important;
    }
}

.featured-area .box-btn{
    margin-top: -2rem !important;
    background-color: $secondary-color !important;
    color: white !important;
    
    filter: drop-shadow(0px 2px 10px #fbae42ad) !important;
}

.featured-area .box-btn:hover{
    background-color: transparent !important;
    color: $secondary-color !important;
}

.ver-card-row{
    margin-top: 3rem !important;
}

.btn-card-row{
    padding: 0rem 4rem !important;
    margin-top: 3rem !important;
}

.featured-area .feature-card{
    background-color: transparent !important;
}

// .featured-area .bi.bi-arrow-right{
//     padding: 0rem !important;
//     color: $secondary-color !important;
//     background-color: transparent !important;
//     font-size: 1.3rem !important;
// }

// .feature-area .info-col{
    
// }


@media only screen and(max-width:768px) {
    .featured-area{
        padding:1rem 1rem 3rem 1rem!important;
    }

    .featured-box{
        border:0px solid #3d3d3a !important;
        padding:4rem 1rem !important;
    }

    .ver-card-row {
        margin-top:0rem !important;
    }

    .btn-card-row{
        padding: 0rem 0rem !important;
        margin-top: -2rem !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    
    .margin-m-t{
        margin-top: -1rem !important;
    }

    .featured-area{
        padding:0rem 0rem 3rem 0rem!important;
    }

    .featured-box{
        border:0px solid #3d3d3a !important;
        padding:4rem 1rem !important;
    }

    .featured-area .ver-card-row {
        margin-top:4rem !important;
    }

    .btn-card-row{
        padding: 0rem 4rem !important;
        margin-top: 3rem !important;
    }
}