@import "src/main.scss";

.work-info {
    display: flex;
    justify-content: space-between;
    align-items: baseline !important;
    position: relative;
}

.pp-work-modal {
    display: none;
}

.work-info-name {
    font-size: $normal-size  !important;
    font-weight: $regular-weight  !important;
}

.work-info-right {
    position: relative;
    // height: 100vh !important;
}

.carousel-control-prev-icon {
    background-image: url("../../../../Global/Images/arrow.svg");
}

.carousel-control-next-icon {
    background-image: url("../../../../Global/Images/arrowRight.svg");
}

.work-car-img {
    width: 30rem;
    height: 30rem;
    object-fit: cover;
}

.additional-images-sec {
    background-color: #ededed !important;
    padding: 4rem !important;
}

.car-img-work {
    // width: 100vw !important;
}

.gal-col {

    position: relative;
    z-index: 123 !important;
}

.gal-col .work-car-shd {
    width: 100% !important;
    // height: 30rem;
    // filter: drop-shadow(15px 15px 0px $primary-color);
}

.gal-col .add-img-overlay {
    position: absolute;
    top: 0;
    height: auto !important;
    width: 100% !important;
    background-color: $secondary-color  !important;
    color: white !important;
    // z-index: 129 !important;
    padding: 4rem !important;
    transition: 0.2s ease-in-out;

    font-family: 400 !important;

    opacity: 0 !important;
    cursor: pointer;
}

.gal-col .add-img-overlay:hover {
    opacity: 1 !important;
}

.add-img-class {
    position: relative;
}

.text-add-img {
    position: absolute;
    top: 1rem;
    right: 1.2rem;
    font-style: italic;
    font-weight: 200 !important;
    font-size: 1rem !important;
    color: white;
    // border: 1px solid black !important;
    padding: 0.5rem 1rem 0.3rem 1rem !important;
    background-color: #3d3d3d4f;
}

.offcanvas {
    width: 80vw !important;
}

.work-overlay-header {
    font-size: 3rem !important;
    font-weight: 800 !important;
    margin-bottom: 3rem !important;
}

.work-area .carousel {
    margin-top: 0rem !important;
}

.work-info-left {
    position: relative;
}

.work-info-img {
    margin-top: 1rem !important;
    height: 80vh !important;
    width: 100vw !important;
}

.ass-text {
    // position: absolute;
    // left: 0rem !important;
    // top: 20rem !important;
    transform: rotate(-90deg);
}

.work-info-right {
    padding: 0rem 0rem 0rem 8rem !important;
}

.work-area .edit-btn-2 {
    font-size: $normal-size  !important;
    cursor: pointer;
}

.margin-work-sec {
    margin-bottom: 10rem !important;
}

.work-area .css-1b3lipl::before {
    background-color: #ededed !important;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
}

.work-title {
    font-size: $para-size  !important;
    margin: -1rem 0rem 2rem 0rem !important;
    font-style: italic !important;
}

.work-area .box-btn {
    // margin-top: 1rem !important;
    background-color: $secondary-color  !important;
    color: white !important;

    filter: drop-shadow(0px 2px 10px #fbae42ad) !important;
}

.work-area .box-btn:hover {
    background-color: transparent !important;
    color: $secondary-color  !important;
}

.work-area .box-btn:hover .bi.bi-search {
    color: $secondary-color  !important;
    margin-left: 0.5rem !important;
}


// Work Overlay
.back-drop {
    width: 20vw !important;
    background-color: rgb(146, 146, 146) !important;
    height: 100vh !important;
    position: fixed !important;
    opacity: 0.8 !important;
    top: 0;
    right: 0;
    z-index: 4;
}

.work-overlay {
    height: 100vh !important;
    width: 80vw !important;
    position: fixed !important;
    font-family: $font-family  !important;
    top: 0;
    left: 0;
    background-color: white !important;
    border-right: 6px solid #FBAF42 !important;
    // filter: drop-shadow(2px 4px 30px rgba(0, 0, 0, 0.24));
    z-index: 999 !important;
    overflow-y: scroll !important;
    // -webkit-box-shadow: 21px -3px 7px -4px rgba(219, 219, 219, 0.63);
    // -moz-box-shadow: 21px -3px 7px -4px rgba(219, 219, 219, 0.63);
    // box-shadow: 21px -3px 7px -4px rgba(219, 219, 219, 0.63);

    .work-overlay-content {
        margin-top: 5em;

        .work-overlay-client {
            font-size: 3em;
            padding: 0 !important;
        }

        .work-overlay-title {
            font-weight: 300 !important;
        }
    }

}

.work-overlay-padding {
    padding: 4rem 5rem 4rem 4rem;
}

.work-overlay-close {
    position: absolute !important;
    right: 2rem;
    top: 2rem;
    width: 3rem !important;
    font-size: 2em !important;
    cursor: pointer !important;
    transition: 0.1s;

    &:hover {
        color: $primary-color
    }
}

.work-overlay-img {
    width: 60vw !important;
    margin: 3rem 0rem !important;
}

.work-overlay-client-name {
    font-size: $header-size  !important;
    font-weight: $super-header-weight;
    line-height: 1.1 !important;
    text-transform: capitalize !important;
}

.work-overlay-description {
    font-size: $normal-size  !important;
    font-weight: 400 !important;
    width: 90%;
    margin-top: 2rem !important;
    color: black !important;
}

.work-area .bi.bi-arrow-right {
    font-size: 3rem !important;
}

.work-overlay-title {
    font-size: $normal-size  !important;
    font-style: italic !important;
    font-weight: 300 !important;
    // margin-top: 2rem;
    // margin: 3rem 0rem 1rem 0rem !important;
}

.css-1jv1kel {
    padding: 2em 2em 0 2em;
    position: relative;
    top: -1.875em;
    left: 2em;
    width: 95%;
    height: 100vh;
}

@media only screen and(max-width:768px) {
    .work-overlay {
        border: none;
    }

    .work-info {
        display: flex;
        justify-content: space-between;
        flex-flow: column wrap;
        align-items: baseline !important;
        position: relative;
    }

    .offcanvas {
        width: 100vw !important;
        // padding: 1rem !important;
    }

    .additional-images-sec {
        overflow-x: hidden !important;
        padding: 2rem !important;
    }

    .gal-col .work-car-shd {
        width: auto !important;
        margin: 0 !important;
        // height: auto !important;
        // filter: drop-shadow(15px 15px 0px $primary-color);
    }

    .gal-col .add-img-overlay {
        height: 100% !important;
        width: auto !important;
    }

    .work-overlay-header {
        font-size: 2rem !important;
        padding: 2rem !important;
        font-weight: 800 !important;
        margin-bottom: 0rem !important;
    }

    .css-1jv1kel {
        padding: 2em 2em 0 2em;
        position: relative;
        top: -1.875em;
        left: 2em;
        width: 95%;
        height: 100% !important;
    }

    .work-info-right {
        padding: 0rem !important;
    }

    .work-info-name {
        font-size: $header-size-mobile  !important;
        font-weight: $regular-weight  !important;
        margin-bottom: -2rem !important;
    }

    .work-area .edit-btn-2 {
        font-size: $misc-size-mobile  !important;

        border-bottom: 1px solid $secondary-color  !important;
    }

    .work-info-img {
        margin-top: 1rem !important;
        height: 20vh !important;
        width: 100vw !important;
    }

    .margin-work-sec {
        margin-bottom: 5rem !important;
    }

    .work-area .css-vcfpfv {
        background-color: $primary-color  !important;
        border-radius: 50% !important;
        outline: 10px solid white;
        display: block;
        margin: 0.5em 0.5em 0.5em -0.5em;
        position: absolute;
        top: 0;
        left: 2em !important;
        width: 1em !important;
        height: 1em !important;
    }

    .work-title {
        font-size: $para-size-mobile  !important;
        font-style: italic !important;
        margin: 2rem 0rem 0rem 0rem !important;
    }


    // Work Overlay
    .work-overlay {
        height: 100vh !important;
        width: 100vw !important;
        position: fixed !important;
        top: 0;
        left: 0;
        background-color: white !important;
        filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.082));
        z-index: 999 !important;
        overflow-y: scroll !important;
        font-family: $font-family  !important;
    }

    .work-overlay-padding {
        padding: 2rem 0 0 0 !important;
    }

    .work-overlay-close {
        position: fixed !important;
        right: 2rem;
        top: 2rem;
        width: 2rem !important;
        cursor: pointer !important;
    }

    .work-overlay-img {
        width: 60vw !important;
        margin: 0rem 0rem !important;
    }

    .work-overlay-client-name {
        font-size: 2rem !important;
        font-weight: $super-header-weight  !important;
        line-height: 1.2 !important;
        margin-bottom: 3rem;
    }

    .work-overlay-description {
        font-size: 1.2rem !important;
        font-weight: $light-weight  !important;
        margin-top: 3rem !important;
        width: 100%;
        color: black !important;
    }

    .work-overlay-title {
        font-size: $normal-size-mobile  !important;
        font-style: italic !important;
        font-weight: 400 !important;
        margin: 2.5rem 0rem 0rem 0rem !important;
    }



    .work-car-shd {
        width: 20rem;
        height: 20rem;
        margin-left: 1rem !important;
        // filter: drop-shadow(15px 15px 0px $primary-color);
    }

}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .work-overlay-description {
        font-size: $normal-size-mobile  !important;
        width: 75%;
        font-weight: $light-weight  !important;
        margin-top: 2rem !important;
        color: black !important;
    }

    .work-overlay-img {
        width: 100vw !important;
    }

    .work-overlay-close {
        position: static !important;
    }

    .offcanvas {
        width: 100vw !important;
        padding: 1rem !important;
    }

    .css-1jv1kel {
        padding: 2em 2em 0 2em;
        position: relative;
        top: -1.875em;
        left: 2em;
        width: 95%;
        height: 100%;
    }

    .work-info-right {
        padding: 0rem 0rem 0rem 0rem !important;
    }

}