@import "src/main.scss";

.bg-area{
    background-image: url('../../Global/Images/magnify.svg');
    background-repeat: no-repeat;
    background-position: left bottom;
}

.bg-area .hero-area{
    padding: 15rem 0rem 10rem 0 !important;
    margin-bottom: 5rem !important;
    background-color: $primary-color !important;
    // height: auto !important;
}

@media only screen and(max-width:768px) {
    .bg-area .hero-area{
        padding: 15rem 1rem 9rem 1rem !important;
    }
    
}

@media only screen and (min-width:768px) and (max-width:1024px) {

}
