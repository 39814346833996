.preLoadArea{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.preload-logo{
    width: 15rem !important;
}

.ErrorArea{
    background-color: #FBAF42 !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-flow: column wrap;
    /* align-items: center; */
    height: 100vh;
}

.error-header{
    /* margin-top: 4rem !important; */
    font-size: 3rem !important;
    display: none;
}

.oops{
    color: #FBAF42;
    text-shadow:
   -1.5px -1.5px 0 #000,  
    1.5px -1.5px 0 #000,
    -1.5px 1.5px 0 #000,
     1.5px 1.5px 0 #000;    
}

.ErrorArea .big-header{
    line-height: 1.1 !important;
    font-size: 7rem !important;
    font-weight: 900 !important;
    color: #000;
    /* width: 75% ; */
}

 .ErrorArea .box-btn{
    margin-top: 2rem !important;
    font-weight: 800 !important;
    color: black !important;
    border-color: black !important;
}
/*
.ErrorArea .box-btn:hover{
    background-color: transparent !important;
    color: #452610 !important;
}

.ErrorArea .box-btn:hover .bi.bi-search{
    color: #452610 !important;
    margin-left: 0.5rem !important;
} */

.ErrorArea .normal{
    font-size: 1.5rem !important;
    font-weight: 400 !important;
    width: 75% !important;
    color: #000 !important;
    line-height: 1.1 !important;
}

.row-below{
    margin-top: -8rem !important;
}

@media only screen and (max-width:768px) {
    .ErrorArea{
        background-color: #FBAF42 !important;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        /* padding: 4rem 1.5rem 4rem 1rem!important; */
        flex-flow: column wrap;
        /* align-items: center; */
        height: 100vh !important;
    }
    
    
    
    .ErrorArea .big-header{
        line-height: 1.1 !important;
        font-size: 3.5rem !important;
        font-weight: 900 !important;
        margin-top: -2rem !important;
        color: #000;
        /* width: 75% ; */
    }
    
    
    .ErrorArea .normal{
        font-size: 1.3rem !important;
        font-weight: 300 !important;
        color: #000 !important;
        line-height: 1.1 !important;
    }
    
    .row-below{
        margin-top: 4rem !important;
    }
}


@media only screen and (min-width:768px) and (max-width:1024px) {
    .ErrorArea{
        background-color: #FBAF42 !important;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        padding: 0 5rem !important;
        flex-flow: column wrap;
        /* align-items: center; */
        height: 100vh !important;
    }
    
    
    
    .ErrorArea .big-header{
        line-height: 1.1 !important;
        font-size: 5rem !important;
        font-weight: 900 !important;
        margin-top: -2rem !important;
        color: #000;
        /* width: 75% ; */
    }
    
    
    .ErrorArea .normal{
        font-size: 1.5rem !important;
        font-weight: 300 !important;
        color: #000 !important;
        width: 50% !important;
        line-height: 1.1 !important;
    }
    
    .row-below{
        margin-top: 4rem !important;
    }
}