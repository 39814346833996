@import "src/main.scss";


.feature-card{
    border: 2px solid $secondary-color;
    background-color: white !important  ;
}

.source{
    font-weight: $header-subheader-weight;
}

.date{
    font-style: italic !important;
}

.btn-grp-2{
    margin-top: 2rem !important;
    display: flex;
    justify-content: space-between;
}

.bi-trash::before {
    content: "\f5de";
    color: #FF4F4F !important;
    font-size: $para-size !important;
    cursor: pointer;
}


.edit-btn-1{
    display: inline-block;
    position: relative;
    font-size: $para-size !important;
    text-align: left !important;
    text-decoration: none;
    border-bottom: 2px solid $secondary-color;  
    overflow: hidden;
    color: $secondary-color !important;

    &::after{
        display:block;
        content: '';
        width: 2.5rem !important;
        margin-top: -2px;
        border-bottom: 4px solid $primary-color;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$secondary-color !important;
    }
}