@import "src/main.scss";

.top-para{
    font-style: italic !important;
    font-weight: $light-weight !important;
}

.feature-area .hero-area{
    padding: 14rem 0rem 8rem 0 !important;
    margin-bottom: 5rem !important;
    background-color: $primary-color !important;
    // height: auto !important;
}

@media only screen and(max-width:768px) {
    .feature-area .hero-area{
        padding: 15rem 1rem 9rem 1rem !important;
    }
    
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .feature-area .hero-area{
        padding: 15rem 1rem 9rem 1rem !important;
    }
}