@import "src/main.scss";

.vertical-page-row{
    margin: 5rem 0rem 10rem 0rem!important;
    padding: 3rem 5rem !important;
    height: auto !important;
    background-color: #ededed !important;
    position: relative !important;
}

.m-v{
    margin:5rem 0 !important;
}

.vertical-page-area .hero-area{
    padding: 15rem 0rem 10rem 0 !important;
    margin-bottom: 5rem !important;
    background-color: $primary-color !important;
    // height: auto !important;
}

.vertical-page-img{
    height: 40rem !important;
    width: 50rem !important;
    // height: auto !important;
}

.bi.bi-server,.bi.bi-journal-bookmark{
    margin-right: 1rem !important;
}

.vertical-row .bi.bi-search{
    margin-right: 1rem !important;
    font-size: 1.5rem !important;
}

.vertical-page-detail-header{
    // margin-top: 3rem !important;
    font-size:  $sub-header-size !important;
    font-weight: $header-subheader-weight !important;
}

.vertical-page-para{
    font-size:  1.1rem !important;
    line-height: 1.7 !important;
    font-weight:300 !important;
}

.vertical-img{
}

.padding-0{
    padding-right:0 !important;
    padding-left:0 !important;
}

.m-b{
    margin: 3rem 0rem;
}

.detail-col-vertical-page{
    padding: 3rem !important;
    // background-color: $primary-color !important;
    color: black !important;

    display: flex;
    justify-content: center;
    align-content: center;
    flex-flow: column wrap;
}

.vertical-row{
    // margin-top: 2rem !important;
    padding: 0rem 7rem !important;
}

.number-vertical{
    font-size: $large-header-size !important;
    font-weight: $super-header-weight !important;
    color: black !important;
    background-color: $primary-color !important;
    padding: 0.5rem 2rem !important;
    position: absolute;
    right: 50%rem;
    top: -6rem;
}

.vertical-row .box-btn{
    background-color: $secondary-color !important;
    color: white !important;
    
    filter: drop-shadow(0px 2px 10px #fbae42ad) !important;
}

.vertical-row .box-btn:hover{
    background-color: transparent !important;
    color: $secondary-color !important;
}

@media only screen and(max-width:768px) {
    .vertical-page-row{
        margin: 5rem 0rem 10rem 0rem!important;
        padding: 3rem 1rem !important;
        height: auto !important;
        background-color: #ededed !important;
        position: relative !important;
    }


    .number-vertical{
        font-size: $large-header-size-mobile !important;
        font-weight: $super-header-weight !important;
        color: black !important;
        background-color: $primary-color !important;
        padding: 0.5rem 2rem !important;
        position: absolute;
        left: 2rem;
        top: -4rem;
    }
    
    .vertical-row {
        padding: 0rem 1rem !important;
    }

    .vertical-page-img {
        height: 100% !important;
        width: 100% !important;
    }

    .detail-col-vertical-page {
        padding: 2rem !important;
        color: black !important;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-flow: column wrap;
    }

    .m-b {
        margin: 1.5rem 0rem;
    }

    .first{
        order: 1 !important;
    }

    .second{
        order: 2 !important;
    }

    .m-v{
        margin-top: 4rem 0 !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {

    .first{
        order: 1 !important;
    }

    .second{
        order: 2 !important;
    }
}