@import "src/main.scss";

.margin-dash{
    // margin-top: 4rem !important;
    // margin-left: -3rem !important;
}

.margin-dash-2{
    margin-top: 3rem !important;
}

.dashboard-header{
    font-size: $large-header-size !important;
    font-weight: $super-header-weight !important;
}

.dash-card{
    padding: 2.5rem 2rem 1rem 2rem!important;
    background-color: $fourth-color !important;
    border: none !important;
    height: 100%;
    margin-bottom: 2rem !important;
}

.card-title-dash{
    font-size: $normal-size !important;
}

.margin-card-dash{
    margin: 2rem !important;
}

.learn-more-btn{
    display: inline-block;
    position: relative;
    overflow: hidden;
    color: $secondary-color !important;

    &::after{
        display:block;
        content: '';
        margin-top: 2px;
        border-bottom: solid 3px $secondary-color;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$secondary-color !important;
    }
}


@media only screen and(max-width:768px) {
    .margin-dash{
        margin-top: 4rem !important;
        // margin-left: -3rem !important;
    }
    .dashboard-header{
        font-size: $large-header-size-mobile !important;
    }

    .card-title-dash{
        font-size: $sub-header-size-mobile !important;
    }
    
    .dash-card{
        // width: 20rem !important;
        padding: 0rem!important;
        background-color: #FDE7AD  !important;
        border: none !important;
        margin-bottom: 0rem !important;
    }
}