@import "src/main.scss";

body {
    // overflow: hidden !important;
    // overflow-y: scroll !important;
}

.bi.bi-grid-1x2,
.bi.bi-list-ul,
.bi.bi-bag,
.bi.bi-newspaper,
.bi.bi-people,
.bi.bi-newspaper, .bi-person-circle{
    color: $secondary-color !important;
    margin-right: 1rem !important;
}

// .right-side-page{
//     height: 100vh !important;
// }

.mobile-navbar{
    display: none !important;
}

.mobile-nav{
    background-color: $primary-color !important;
}

.mobile-nav-bottom{
    display: none !important;
}

.right-dash-h{
    height: 100% !important;
    padding-bottom: 5rem !important;
}

@media only screen and(max-width:768px) {
    .mobile-navbar{
        display: none !important;
    }

    .logo-mobile{
        width: 3.5rem !important;
        // margin: 2rem 0rem 1rem 0rem!important;
    }

    span.navbar-toggler-icon {
        background-image: url("../Global/images/menu_icon.svg") !important;
        border: none !important;
        width: 4rem;
    }

    .navbar-light .navbar-toggler {
        color: rgba(0,0,0,.55);
        border-color: none;
    }

    .profile-block{
        display: flex;
        justify-content: endt !important;
        align-items: center !important;
        margin: 1rem 40rem 0rem 1rem !important;
    }

    .mobile-nav-bottom {
        display: flex !important;
        height: 5.5rem !important;
        width: 100vw !important;
        background-color: #FBAF42 !important;
        position: fixed !important;
        bottom: 0rem !important;
        left: 0rem !important;
        z-index: 1;
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 1rem;
    }

    .pagination{
        display: flex;
        justify-content: start;
        margin-left: 0rem !important;
    }

    .bi.bi-grid-1x2,
    .bi.bi-list-ul,
    .bi.bi-bag,
    .bi.bi-newspaper,
    .bi.bi-people,.bi-person-circle{
        color: $secondary-color !important;
        margin-right: 0rem !important;
    }

    
.work-card-deck{
    margin: 5rem 0rem 0rem 0rem!important;
    padding: 0rem !important;
}
    
}

@media only screen and (min-width:1024px) and (max-width:1200px) {
    .right-dash-h{
        height: 100% !important;
        padding-bottom: 5rem !important;
        padding-left: 5rem !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .mobile-navbar{
        display: none !important;
    }

    .navbar-collapse {
        flex-basis: 100%;
        flex-grow: 1;
        align-items: center;
        padding: 2rem 1rem !important;
    }
}