@import "src/main.scss";

.contactCTA-area{
    height: 100% !important;
    
    position: relative !important;
    background-color: $tertiary-color !important;
    padding:8rem 0rem !important;
}

.pad-con{
    padding: 0rem 4rem !important;
}

.header-con{
    margin-left: -2rem;
    color: rgba(0, 0, 0, 0.116) !important;
    font-size: 10rem !important;

    position: relative;
    z-index: 1;
}

.con-info-col{
    display: flex !important;
    align-items:start !important;
    justify-content: center !important;
    height: auto !important;

    flex-direction: column !important;
}

.contactCTA-img{
    position: relative;
    z-index: 2;
}

.con-info-right-col{
    position: relative;
    z-index: 2;
}

@media only screen and(max-width:768px) {
    .contactCTA-area{
        padding:5rem 1rem 3rem 1rem!important;
    }

    .pad-con{
        padding: 0rem 0rem !important;
    }
    

    .contactCTA-img{
        // width: 40rem !important;
        // height: 30rem !important;
        z-index: 1;
    }

    .header-con{
        margin-left: 0rem;
        margin-top: 2rem !important;

        color: #212529 !important;
        font-size: 4rem !important;

        position: relative;
        z-index: 2;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    
    .contactCTA-img{
        width: 40rem !important;
        height: 20rem !important;
    }

    .header-con{
        margin-left: -2rem;
        margin-top: 0rem !important;

        color: rgba(0, 0, 0, 0.116) !important;
        font-size: 6rem !important;

        position: relative;
        z-index: 1;
    }
}