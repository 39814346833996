@import "src/main.scss";

.fa-article{
    width: 100% !important;
    padding: 3rem 2.5rem!important;
    background-color: #E5E5E5 !important;

    margin-top: -1rem !important;
}

.edit-btn-2 {
    background-color: transparent;
    border: none;
    border-radius: 0;

    &:hover {
        background-color: transparent;
    }
}

.btn-primary {
    &:focus {
        background-color: unset !important;
        box-shadow: none !important;
    }
}

.feature-link {
    font-size: $normal-size !important;
    a {
        color: $primary-color;
        font-weight: light;
        transition: 0.5s;
        
        &:hover {
            color: $secondary-color;
        }

        &::after {
            background-color: transparent !important;
        }

        &:focus {
            background-color: transparent !important;
        }
    }
}

.fa-card-header{
    font-size: 3rem !important;
    font-weight: $header-subheader-weight !important;
}

.fa-info-col{
    height: auto !important;
    display: flex;
    align-items:start;
    justify-content: center;

    flex-direction: column;
}

.owl-nav{
    margin-top: -2rem !important;
}

.feature-page-area{
    & .carousel{
        margin: 3rem 0rem!important;
    }
}

.feature-page-area .date{
    font-weight: $light-weight !important;
    font-style: italic !important;
}

@media only screen and(max-width:768px) {
    .fa-article{
        width: 100% !important;
        padding: 3rem 2rem!important;
        background-color: #E5E5E5 !important;
    
        margin-top: 0rem !important;
    }

    .fa-card-header{
        font-size: $header-size-mobile !important;
        font-weight: $header-subheader-weight !important;
    }

    .fa-info-col{
        margin-top: 3rem;
    }
    
}

@media only screen and (min-width:768px) and (max-width:1024px) {
}
