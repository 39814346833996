@import "src/main.scss";


.add-work-btn{
    background-color: $secondary-color !important;
    border: 1px solid $secondary-color !important;
    width: 10rem !important;
    padding: 0.8rem 0;
    color: white;
    transition: 0.3s ease;

    &:hover{
        background-color:white !important;
        color:$secondary-color !important;

    }

    &::after{
        color: $secondary-color !important;
    }
}

.sortableHelper {
    z-index: 9999;
}

.sortingCard {
    margin: 1em 0;
    .sortingCard-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: #ededed ;
        font-size: 1.5em;
    }
}

.save-sorting-btn {
    background-color: $secondary-color !important;
    border: 1px solid $secondary-color !important;
    width: 100%;
    padding: 0.8rem 0;
    color: white;
    transition: 0.3s ease;

    &:hover{
        background-color:white !important;
        color:$secondary-color !important;

    }

    &::after{
        color: $secondary-color !important;
    }
}


.sort-work-btn{
    background-color: transparent;
    border: 1px solid $secondary-color !important;
    width: 10rem !important;
    padding: 0.8rem 0;
    color: $secondary-color;
    transition: 0.3s ease;

    &:hover{
        background-color:$secondary-color !important;
        color:white !important;

    }

    &::after{
        color: $secondary-color !important;
    }
}

.card-title-dash{
    font-size: $normal-size !important;
}

.btn-grp{
    margin-top: 2rem !important;
    display: flex;
    justify-content: space-between;
}

.edit-btn{
    width: 6rem !important;
    background-color: transparent !important;
    color: #65ec26 !important;
    border: 2px solid #65ec26;
    transition: 0.3s ease;

    &:hover{
        background-color: #65ec26 !important;
        color: white !important;
        border: 2px solid #65ec26;
    }
}

.del-btn{
    width: 6rem !important;
    background-color: #FF4F4F !important;
    color: white !important;
    border: 2px solid #FF4F4F;
    transition: 0.3s ease;

    &:hover{
        background-color: transparent !important;
        color: #FF4F4F !important;
        border: 2px solid #FF4F4F;
    }
}

.work-card-deck{
    margin: 4rem 0rem 0rem 0rem!important;
}

.work-card{
    padding: 1rem !important;
    margin-bottom:2rem !important;
    background-color: #ededed !important;
    transition: transform 0.4s ease-in-out;
    border-radius: 12px !important;
    border: none !important;
    height: auto !important;
}

.work-img{
    border-radius: 10px !important;
}

.add-modal-title{
    font-size: $normal-size;
    font-weight: $header-subheader-weight !important;
}

.work-modal{
    padding: 0rem !important;
}

.fa-jumbotron{
    background-color: #ededed !important;
    border-radius: 12px !important;
    border: none !important;
    height: auto !important;

    margin-bottom: 4rem !important;
    padding: 2rem !important;
}

.admin-works-page-sub-header{
    font-size: 1.8rem !important;
    font-weight: $header-subheader-weight !important;
}

.fa-card-row{
    margin-top: 0rem !important;
}

.bi.bi-plus-lg{
    font-size: 2rem !important;
    color: #3d3d3a !important;
}

.fa-work-card{
    background-color: #c4c4c4 !important;
    border-radius: 8px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 5rem !important;
}

.fa-work-card-jumbo{
    background-color: transparent !important;
}

.fa-jumbotron .form-control{
    background-color: transparent !important;
}