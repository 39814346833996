@import "src/main.scss";

.footer-area{
    background-color: #131312 !important;
    height: 100%;
    padding: 5rem 7rem 2rem 7rem !important;
    
    position: relative !important;

    & .ham-social{
        color: #C4C4C4 !important;
    }
}

.footer-link:hover .foot-para{
    color: #fff !important;
    font-weight: 500 !important;
}

.logo-foot{
    width: 8rem !important;
}

.foot-para{
    color: #b1b1b1 !important;
    text-decoration: none !important;
}

.footer-sub-head{
    font-size: 2.5rem !important;
    font-weight: $super-header-weight !important;
    color: white !important;
}

.footer-area .form-control{
    border-color: transparent !important;
    background-color: transparent !important;
    border-bottom: 2px solid white !important;
    border-radius: 0px !important;
}


.footer-area .form-control::placeholder {

    color: #9e9e9e !important;
    font-weight: 300 !important;
    opacity: 1;

}


.footer-area .box-btn{
    border: 2px solid white !important;
    color: white !important;
}

.footer-area .footer-hr{
    margin: 4rem 0rem 2rem 0rem;
    border: 2px solid rgb(114, 114, 114)!important;
    z-index: 1 !important;
}

.bottom-foot{
    display: flex;
    justify-content: center;
}

.footer-area .social-icon{
    color: white !important;
}

@media only screen and(max-width:768px) {
    .footer-area{
        padding:1rem 1rem 3rem 1rem!important;
    }

    .mobile-margin{
        margin-top: 4rem !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    
    .mobile-margin{
        margin-top: 4rem !important;
    }
}