@import "src/main.scss";

.profile-block{
    display: flex;
    justify-content: end;
    align-items: center !important;
    margin: 2rem 0rem 0rem 0rem !important;
}

.profile-image{
    width: 2.5rem !important;
}

.spacing-p{
    margin: 0rem 0.3rem !important
}



@media only screen and (max-width:768px){
    .profile-block{
        display: flex;
        justify-content: end;
        align-items: center !important;
        margin: 2rem 0.5rem 0rem 0rem !important;
    }

    .profile-area .logout-space{
        position: absolute;
        top: 5rem;
        left: 0rem !important;
        padding: 2rem;
        align-items: center !important;
        background-color: #fff;
        filter: drop-shadow(0px 5px 5px #fbae4279);
        width: 100vw !important;
        height: 8rem !important;
        transition: 4s ease-in-out;
        border-bottom: 3px $primary-color solid !important;
    }

    
.bi.bi-caret-down{
 margin-left: 0.5rem !important;
 margin-top: 1rem !important;
}
}