@import "src/main.scss";

.ham-area{
    background-color: $primary-color !important;
    position: fixed !important;
    padding: 3rem !important;
    height: 100% !important;
    width: 40vw !important;
    top: 0;
    right: 0;
    z-index: 999 !important;
    overflow-y: hidden !important;
    transition: all 1s ease-in-out;
    // overflow-y: scroll !important;
}


.ham-area .navbar{
    padding: 0rem !important;
}

.goBack-logo{
    width: 4rem !important;
    padding: 0.5rem 0rem!important;
    position: absolute;
    top: 2rem !important;
    right: 7rem !important;
    // display: none !important;
}

.ham-menu-link h2{
    font-size: 4rem !important;
    font-weight: $super-header-weight !important;
    margin-bottom: 1rem !important;
    color: $primary-color !important;
    text-decoration: none !important;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;    

     &:hover{
         color: black !important;
         text-shadow:
            0px 0px 0 #000;
     }   
}

.active-nav .ham-menu-link h2{
    color: black !important;
    text-shadow:
       0px 0px 0 #000 !important;
 }

.ham-info{
    height: 100vh !important;
    display: flex;
    justify-content: end;
    flex-direction: column;
    padding: 6rem !important;
}

.ham-links{
    height: 100vh !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.ham-logo{
    width: 12rem !important;
}

.ham-social .social-icon{
    margin:0 2rem 0 0;
}

.goBack-link{
    cursor: pointer !important;
}


@media only screen and(max-width:768px) {
    .goBack-logo{
        width: 4rem !important;
        padding: 0.5rem 0rem!important;
        position: absolute;
        top: 1rem !important;
        right: 2rem !important;
    }

    .ham-area{
        width: 100vw !important;
        padding-top: 6rem !important;
        overflow-y: scroll !important;
    }

    .ham-menu-link h2{
        font-size: 2.5rem !important;
        margin-bottom: 1rem !important;  
        text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000; 
    }

    .ham-info {
        margin-top: 4rem !important;
        height: 100% !important;
        display: flex;
        justify-content: start;
        flex-direction: column;
        padding: 2rem !important;
    }

    .ham-links{
        height: 100% !important;
    }
    
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .ham-area{
        padding-top: 10rem !important;
        height: 100vh !important;
        width: 60vw !important;
        overflow-y: scroll !important;
    }
    

    .ham-menu-link h2{
        font-size: $header-size !important;
        margin-bottom: 1rem !important;  
        text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000; 
    }

    .ham-info {
        display: none !important;
    }

    .goBack-logo{
        width: 4rem !important;
        padding: 0.5rem 0rem!important;
        position: absolute;
        top: 3rem !important;
        right: 3rem !important;
    }
}

@media only screen and (min-width: 1200px) {
    
}