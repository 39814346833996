@import '/src/main.scss';

.auth-container {
    height: 100vh;
    // background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

h1 {
    font-size: $large-header-size;
}

.auth-input {
    background-color: transparent;
    width: 20rem;
    border: unset !important;
    border-radius: unset;
    border-bottom: 1px solid $secondary-color !important;
}

.auth-input:focus {
    box-shadow: none;
}


.button-row {
    // background-color: yellow;
    padding: 0px 13px;
    
    .button-col {
        padding: 0;
        // background-color: grey;
        text-align: center;

        .auth-button-primary {
            border-radius: unset;
            background-color: $primary-color;
            width: 90%;
            height: 3em;
            border: 2px solid $primary-color;
        }

        .auth-button-primary:hover {
            background-color: white;
            color:$primary-color;
        }

        .auth-button-secondary {
            text-decoration: none;
            border-radius: unset;
            width: 90%;
            height: 3em;
            border: 2px solid $secondary-color;
            background-color: transparent;
            color: $secondary-color;
            
        }

        .auth-button-secondary:hover {
            background-color: $secondary-color;
            color:white
        }
    }

}
