@import "src/main.scss";

.form-control{
    border-color: transparent !important;
    border-bottom: 2px solid black !important;
}

.img-col{
}

.add-img-btn {
    padding: 10px !important;
    margin: 0 1em !important;
    width: fit-content;
    background-color: unset !important;

}

.add-img-btn.del {
    border-color: red;
    // background-color: red !important;
}

// .bi.bi-trash{
//     color: white !important;
// }

.add-work-area{
    & .card-body {
        padding:0rem;
    }
}

.add-work-area .work-card{
    cursor: pointer;
    height: 90% !important;
    display: flex;
    justify-content: center;
}

.bi.bi-plus{
    font-size: 5rem !important;
}

.additional-img-row{
    background-color: #f8f8f8 !important;
    padding: 1rem !important;
    border-radius: 12px !important;
}

.add-img-btn.edit {
    border-color: $secondary-color;
    color: $secondary-color;
    justify-content: space-evenly;
}

.add-work-img{
    border-radius: 15px !important;
}

.submit-btn{
    margin: 3rem 0rem;
    width: 10rem !important;
    background-color: $secondary-color !important;
    color: white;
    border: none;

    font-size: $para-size !important;
    padding: 1rem 0rem !important;
}
.submit-btn-small{
    margin: 0.1rem 0rem;
    width: 6rem !important;
    background-color: $secondary-color !important;
    color: white;
    border: none;

    font-size: $para-size !important;
    padding: 0.3rem 0rem !important;
}



.margin-dash-4{
    margin-top: 3rem !important;
}

.pro-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.margin-t{
    margin-top: 1rem !important;
}

.bi.bi-chevron-left{
    color: $secondary-color !important;
    text-decoration: none !important;
}

@media only screen and(max-width:768px) {
    .img-col{
        margin-top: 2rem !important;
        overflow: hidden;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .img-col{
        margin-top: 2rem !important;
    }

    .add-work-area{
        & .card-body {
            padding:1rem;
        }
    }
    
}

.add-work-img::file-selector-button{

    
    width: 10rem !important;
    border-radius: 12px !important;
    padding: 1rem 0rem !important;
    border: none !important;
    cursor: pointer !important;
}