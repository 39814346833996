@import "src/main.scss";

.form-control{
    border-color: transparent !important;
    border-bottom: 2px solid black !important;
}

.img-col{
}

.add-work-img{
    border-radius: 15px !important;
}

.submit-btn{
    margin: 3rem 0rem;
    width: 10rem !important;
    background-color: $secondary-color !important;
    color: white;
    border: none;

    font-size: $para-size !important;
    padding: 1rem 0rem !important;
}

.margin-dash-4{
    margin-top: 3rem !important;
}

.pro-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.margin-t{
    margin-top: 1rem !important;
}

.bi.bi-chevron-left{
    color: $secondary-color !important;
    text-decoration: none !important;
}


add-work-img::file-selector-button {

    font: inherit;
    width: 50rem !important;
    border-radius: 12px !important;
    height: 20rem !important;
    border: none !important;
    cursor: pointer !important;
}