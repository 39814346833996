@import "src/main.scss";

.sidebar{
    background-color: $primary-color;
    height: 100% !important;
    text-align: center !important;
    position: fixed;
}

.logo{
    width: 7rem !important;
    margin: 2rem 0rem 1rem 0rem!important;
}

.menuList{
    padding: 2rem;
}
.menuItem .menu-d{
    margin-bottom: 0.5rem !important;
    transition: .2s ease-in-out;
    padding: 1rem !important;

    &:hover{
        background-color: #FCBF68 !important; 
        padding: 1rem !important;
        border-radius: .5rem !important;
        // margin-bottom: 0rem !important;
    }

    
}


.active .menu-d{
    background-color: #FDE7AD !important; 
    padding: 1rem !important;
    border-radius: .5rem !important;
    }


.menu-d{
    display: flex;
    justify-content: start !important;
    align-items: center !important;
}

.nav-icons{
    margin-right: 1rem !important;
    width: 2rem !important;
}

.text-d{
    text-decoration: none !important;
}

.menu-link{
    font-weight: $light-weight !important;
    color: $secondary-color !important;
    font-size: $misc-size !important;
}

.logout-space{
    position: absolute;
    bottom: 1rem;
    left: 1.4rem !important;
    align-items: center !important;
}

.logout-btn{
    
    width: 10rem !important;

    background-color: transparent !important;
    color: $secondary-color !important;
    border: 2px solid $secondary-color;
    padding: 0.8rem 0;
    font-weight: $header-subheader-weight;
    transition: ease-out 0.3s !important;

    &:hover{
        background-color: $secondary-color !important;
        color: $primary-color !important;
    }
}
   
.mobile-nav-link{
    display: none;
}



@media only screen and(max-width:768px) {
        .sidebar{
            display: none !important;
        }

        .menuItem .menu-d{
            margin-bottom: 0.5rem !important;
            transition: .2s ease-in-out;
            padding: 1rem !important;
        
            &:hover{
                background-color: #FCBF68 !important; 
                padding: 1rem !important;
                border-radius: .5rem !important;
                // margin-bottom: 0rem !important;
            }
        
            
        }
        
        
        .active .menu-d{
            background-color: #FDE7AD !important; 
            padding: 1rem !important;
            border-radius: .5rem !important;
            }

            .mobile-nav-link{
                display: flex;
                justify-content: center;
                flex-flow: column wrap;
                align-items: center;
            }

            .mobile-menu-link{
                font-size: 0.5rem !important;
                color: $secondary-color !important;
            }
        
    }

    @media only screen and (min-width:768px) and (max-width:1024px) {
        .sidebar{
            display: block !important;
            height: 100vh !important;
            position: fixed !important;
            top: 0;
            left: 0;
        }
    }