@import "src/main.scss";

.form-area{
    height: 100% !important;
    position: relative !important;

}

.contact-page-area .hero-area{
    padding: 15rem 0rem 5rem 0 !important;
    // margin-bottom: 5rem !important;
    // background-color: $primary-color !important;
    // height: auto !important;
}

.contact-details-row-right-col{
    background-color: $primary-color !important;
    padding: 4rem 2rem 10rem 5rem !important;
    margin-top: -15rem;
    position: sticky;
    top: 1rem;
    color: black !important;
}
.form-area .para{
    font-weight: $light-weight !important;
}

.details-header{
    font-weight: $regular-weight !important;
    font-size: $normal-size !important;
}


.contact-form-row{
    background-color: #EDEDED !important;
    height: 100% !important;
    width: 100vw !important;
    margin-top: -14rem;
    padding-bottom: 5rem !important;
}

.form-col{
    padding: 5rem 1rem 5rem 8rem!important;
}

.form-area .form-control{
    background-color: transparent !important;
}

.form-area .box-btn{
    width: 100% !important;

}

.scroll-down{
    position: absolute;
    top: 6rem !important;
    z-index: 2 !important;
    left:2rem;
    width: 6rem !important;
    animation: spin 8s infinite linear!important;
}

.form-area .edit-btn-2{
    font-family: $font-family !important;
    letter-spacing: 0 !important;
    display: inline-block;
    position: relative;
    font-size: $para-size !important;
    font-weight: 400 !important;
    text-align: left !important;
    text-decoration: none;
    background-color: transparent !important;
    // border-bottom: 3px solid $secondary-color !important;  
    overflow: hidden;
    color: $secondary-color !important;

    &::after{
        display:block;
        content: '';
        width: 100% !important;
        margin-top: -2px;
        border-bottom: 4px solid $secondary-color !important;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%;
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$secondary-color !important;
    }
}

@keyframes spin{
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}

@media only screen and(max-width:768px) {
    .contact-details-row-right-col{
        background-color: $primary-color !important;
        padding: 4rem 2rem 10rem 5rem !important;
        margin-top: 0rem;
        position: sticky;
        top: 1rem;
    }

    .contact-form-row{
        background-color: #EDEDED !important;
        height: 100% !important;
        width: 100vw !important;
        margin-top: 0rem;
        padding-bottom: 5rem !important;
        margin-left: 0rem !important;
    }

    .form-col{
        padding: 4rem 2rem!important;
    }

    .scroll-down{
        display: none;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .contact-details-row-right-col{
        background-color: $primary-color !important;
        padding: 4rem 2rem 4rem 5rem !important;
        margin-top: 0rem;
        position: sticky;
        top: 1rem;
    }

    
    .scroll-down{
        display: none;
    }

    .contact-form-row{
        background-color: #EDEDED !important;
        height: 100% !important;
        width: 100vw !important;
        margin-top: 0rem;
        padding-bottom: 5rem !important;
    }
}