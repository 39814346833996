@import "src/main.scss";

.hero-area{
    padding: 25vh 0 5rem 0!important;
    // height: 100% !important;
  
// background: rgb(251,175,66) !important;
    // background-image: url('../../../../Global/Images/bg.jpg');
    // background-position: top;
    // background-repeat: no-repeat;
    // background-size: cover;

    height: 100% !important;
    position: relative;

    // color: black !important;
    // cursor: url("../../../../Global/Images/cursor.svg")16 16, context-menu !important;
}

.home-hero-area .hero-area{
background: radial-gradient(circle, rgba(251, 174, 66, 0.486) 3%, rgba(255,255,255,0.4002333355217087) 100%) !important; 

}

// .hero-para-line-grp{
//    background-color: $primary-color !important;
// //    height: 2rem !important;
// }

.home-hero-area .hero-area .para{
    text-align: center !important;
}

.hero-para-line-1{
    position: relative;
    display: inline;
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 50%, #FBAF42 100%);
}

// .hero-para-line-1::after{
//     content: "";
//     background-color: $primary-color !important ;
//     position: absolute;
//     bottom: 0;
//     z-index: 1;
//     left: 0;
//     width: 100%;
//     height: 50%;
// }

// .hero-para-line-1::selection {
//     background: #fcf113;
// }


.hero-para{
    width: 30%;
}

.hero-area .normal{
    font-size: 1.3rem !important;
}

.hero-row-flex{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    align-content: center;
}

.hero-header-div{
    display: flex;
    flex-flow: column wrap;
    align-items: center;
}

.hero-area .bi.bi-search{
    color: $primary-color !important;
    margin-left: 0.5rem !important;
}

.hero-m{
    margin: 2rem 0rem 1rem 0rem!important;
    width: 75%;
}

.hero-header-span{
    // font-size: 4rem !important;
    text-transform: capitalize !important;
}

.home-hero-area .hero-area{
    // background-color: #f8f8f8 !important;
}

.estd-text{
    position: absolute;
    bottom: 7rem !important;
margin-top: -5rem !important;
    font-size: 3rem !important;
    line-height: 1.0;
    font-weight: $super-header-weight !important;
    margin-bottom: 1rem !important;
    color: white !important;
    text-decoration: none !important;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;    
}

.client-text{
    position: absolute;
    bottom: 7rem !important;

    font-size: 3rem !important;
    line-height: 1.0;
    font-weight: $super-header-weight !important;
    margin-bottom: 1rem !important;
    color: white !important;
    text-decoration: none !important;
    text-shadow:
   -1px -1px 0 #000,  
    1px -1px 0 #000,
    -1px 1px 0 #000,
     1px 1px 0 #000;    
}

.hero-row{
    // text-align: center !important;
}



.hero-area .box-btn{
    margin-top: 1rem !important;
    background-color: $secondary-color !important;
    color: white !important;
    
    filter: drop-shadow(0px 2px 10px #fbae42ad) !important;
}

.hero-area .box-btn:hover{
    background-color: transparent !important;
    color: $secondary-color !important;
}

.hero-area .box-btn:hover .bi.bi-search{
    color: $secondary-color !important;
    margin-left: 0.5rem !important;
}


.home-hero-area .bi.bi-arrow-left, .home-hero-area .bi.bi-arrow-right{
    // background-color: $secondary-color !important;
    color: #3d3d3a !important;
    // padding: 0.8rem;
    // border-radius: 8px !important;
    font-size: 2rem !important;
}

.hero-area .owl-nav{
    margin-top: 1rem !important;
}

.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
        font-size: inherit;
    font-size: 2rem !important;
    margin: 0rem 2rem 0rem 0rem !important;
}

.owl-carousel {
    width: 100%;
    position: relative;
    z-index: 0 !important;
}

.edit-btn-2{
    font-family: $font-family !important;
    letter-spacing: 0 !important;
    display: inline-block;
    position: relative;
    font-size: $para-size !important;
    font-weight: 400 !important;
    text-align: left !important;
    text-decoration: none;
    border-bottom: 3px solid $primary-color !important;  
    overflow: hidden;
    color: $secondary-color !important;

    &::after{
        display:block;
        content: '';
        width: 5.5rem !important;
        margin-top: -2px;
        border-bottom: 4px solid $secondary-color !important;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%;
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$secondary-color !important;
    }
}

.carousel{
    // position: absolute;
    // right: 0rem;
    margin-top: 8rem !important;
    cursor: pointer !important;
}

.owl-stage{
    // padding-bottom: 2rem !important;
}

.carousel-img{
    height: 100% !important;
    // width: 40rem !important;
    // filter: drop-shadow(13px 16px 0px $primary-color) !important;
}

.side-col{
    display: flex !important;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column !important;
}

.side-text{
    font-size: $misc-size !important;
    transform: rotate(90deg);
    text-align: center !important;
    color: $secondary-color;
    letter-spacing: 4px !important;
}

.side-col-mobile{
    display: none;
}

.only-desktop{
    display: flex;
}

.only-mobile{
    display: none;
}


.home-details-row-right-col{
    background-color: $primary-color !important;
    padding: 4rem 2rem 4rem 5rem !important;
    // margin-top: -13rem;
    margin-bottom: -21rem;
    position: sticky;
    top: 5rem;
    color: black !important;
    // animation: up-down 1s infinite !important;
}

// @keyframes up-down {
//     0% {
//         transform: translateY(0);
//     }
//     50% {
//         transform: translateY(-20px);
//     }
//     100% {
//         transform: translateY(0);
//     }
// }



.left-hero-col{
    // text-align: center;
    position: relative;
    z-index: 8;
}

.dots{
    width: 10rem !important;

    position: absolute;
    top: -3rem !important;
    left: 3rem !important;
}

.lower-dots{
    width: 10rem !important;

    z-index:-1;
    position: absolute;
    bottom: 0rem !important;
    right: -3rem !important;
}

.upper-dots{
    width: 10rem !important;

    position: absolute;
    top: 3rem !important;
    right: 0rem !important;
}

.semi-circle{
    position: absolute;
    top: 15rem !important;
    right: 0rem !important;
}

.dots-works{
    width: 10rem !important;

    position: absolute;
    top:-4rem !important;
    right: -1rem !important;

    z-index: 2 !important;
}

@media only screen and(max-width:768px) {
    .hero-area{
        padding: 13rem 1rem 2rem 1rem!important;
        height: 100% !important;
        // background-color: white !important;
        position: relative !important;
    }

    .hero-row-flex{
        display: flex;
        flex-flow: column wrap;
        align-items: flex-start;
        
    }    

    .hero-row{
        text-align: left !important;
    }
    
    .side-col{
        display: none;
    }

    .side-col .side-text{
        display: none;
    }

    .only-mobile{
        display: block;
    }

    .only-desktop{
        display: none;
    }
    

    .side-mobile{
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 80%;
        top: 15rem !important;
        right: -8rem !important;
        transform: rotate(90deg);

    }

    .side-text-1{
        font-size: $misc-size-mobile !important;
    }

    .carousel {
        margin-top: 8rem !important;
    }

    .left-hero-col{
        padding-right: 4rem !important;
        text-align: left !important;
    }

    .home-details-row-right-col{
        background-color: $primary-color !important;
        padding: 3rem 2rem 5rem 3rem !important;
        // margin-top: 3rem;
        margin-bottom: 3rem;
        // position:inherit;
        // z-index: 9;
        color: black !important;

    }

    .hero-para{
        width: auto !important;
    }

    .dots{
        width: 7rem !important;
    
        position: absolute;
        top: -3rem !important;
        left: -2rem !important;
    }

    .upper-dots{
        width: 10rem !important;
    
        position: absolute;
        top: 3rem !important;
        right: 0rem !important;
    }
    
    .semi-circle{
        position: absolute;
        top: 15rem !important;
        right: 0rem !important;
    }
    
    .dots-works{
        display: none;
    }

    .hero-area .normal {
        font-size: 1.1rem !important;
    }
    .home-hero-area .hero-area .para {
        text-align: left !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    body{
        width: 100vw !important;
    }
    .side-col{
        display: none;
    }

    
    .hero-para{
        width: 50% !important;
    }


    .side-col .side-text{
        display: none;
    }

    .only-mobile{
        display: block;
    }

    .only-desktop{
        display: none;
    }    

    .side-mobile{
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 40%;
        top: 16rem !important;
        right: -7rem !important;
        transform: rotate(90deg);

    }

    .side-text-1{
        font-size: $misc-size-mobile !important;
    }

    .home-details-row-right-col{
        // background-color: $primary-color !important;
        // padding: 2rem !important;
        // margin-top: 0rem;
        // margin-left: -1rem !important;
        // margin-bottom: 3rem;
        // position: sticky;
        // top: 1rem;
        // color: black !important;
        display: none;
    }
}