@import "src/main.scss";

.vertical-area{
    height: 100% !important;
    
    position: relative !important;
    background-color:#f8f8f8 !important;
    padding:8rem 0rem !important;
    // color: black !important;
}



.info-col{
    display: flex;
    align-items:start;
    justify-content: center;
    height: 100% !important;

    flex-direction: column;
}

.home-vertical-card{
    background-color: white !important;
    // border: 4px solid #fbae42 !important;
    padding: 1rem !important;
    border: none !important;
    filter: drop-shadow(0px 2px 10px #fbae4288) !important;
    // border-radius: 12px !important;
    color: black !important;
}

.service{
        margin-top: 5rem !important;
    }

.service-card{
    border: 2px solid #452610;
    padding: 2rem !important;
}

.connector{
    display: flex;
    flex-wrap: row wrap;
    align-items: center;
}

.connector-element{
    display: flex;
    flex-wrap: row wrap;
    align-items: center;
}

.connector-dot{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: $primary-color !important;
}

.connector-line{
    width: 3rem !important;
    border: 2px dashed #452610;
    margin: 0rem 1rem !important;
}

.vertical-symbols{
    width: 100% !important;
    height: 10rem !important;
    text-align:  center !important;
    // border-radius: 10px !important;
}

.ver-card-row{
    padding: 0rem 4rem !important;
}

.vertical-area .ver-card-row{
    margin-top: 5rem !important;
}

.mt-d{
    margin: 1.6rem 0 !important;
}

.vertical-area .edit-btn-2{
    font-family: $font-family !important;
    letter-spacing: 0 !important;
    display: inline-block;
    position: relative;
    font-size: $para-size !important;
    font-weight: 400 !important;
    text-align: left !important;
    text-decoration: none;
    border-bottom: 3px solid $primary-color !important;  
    overflow: hidden;
    color: $secondary-color !important;

    &::after{
        display:block;
        content: '';
        width: 5.5rem !important;
        margin-top: -2px;
        border-bottom: 4px solid $secondary-color!important;  
        transform: scaleX(0);  
        transition: transform 250ms ease-in-out;
        transform-origin:100% 50%;
    }

    &:hover::after{
        transform: scaleX(1);
        transform-origin:0 50%;
        cursor: pointer !important;
        text-decoration-color:$primary-color !important;
    }
}

.vertical-area .box-btn{
    background-color: $secondary-color !important;
    border: 2px solid $primary-color !important;
    padding: 0.8rem 2rem !important;
    color: white !important;
    font-size: $para-size !important;
    font-family: $header-subheader-weight !important;
    transition: 0.3s ease-in-out !important;

    &:hover{
        background-color: transparent !important;
        color: $secondary-color !important;
        border: 2px solid $secondary-color !important;
    }
}

.vertical-area .big-header{
    font-size: 4rem !important;
}

@media only screen and(max-width:768px) {
    .vertical-area{
        padding:5rem 1rem 3rem 1rem!important;
    }

    .vertical-area .big-header{
        font-size: 3rem !important;
    }

    .margin-m-t{
        margin-top: 2.2rem !important;
    }

    .mt-d{
        margin: 0.8rem 0 !important;
    }

    .ver-card-row{
        padding: 3rem 0rem !important;
    }

    .home-vertical-card{
        margin-bottom: 2rem !important;
    }

    .vertical-area .ver-card-row{
        margin-top: 1rem !important;
    }

    .service-card{
        border: 2px solid #452610;
        padding: 2rem !important;
    }
    
    .connector-dot{
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background-color: $primary-color !important;
    }
    
    .connector-line{
        width: 1rem !important;
        border: 1px dashed #452610;
        margin: 0rem 0.5rem !important;
    }

    .service{
        margin-top: 2rem !important;
    }
   
    
    
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    
    .margin-m-t{
        margin-top: -1rem !important;
    }
    
    .service{
        margin-top: 2rem !important;
    }
}