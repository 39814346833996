@import "src/main.scss";

.about-area{
    position: relative !important;
    padding: 13rem 0rem 0rem 0!important;
    height: 100% !important;
    background-color: white !important;
}

.sec-row-about{
    margin-top: -4rem !important;
}

#big-header-about{
    z-index: 4 !important;
    position: relative;
}


.sec-row-img{
    z-index: 3 !important;
    position: relative;
}

.sec-row-about{
    margin-top: -6rem !important;
}


.about-past{
    background-color: $primary-color !important;
    padding: 8rem 6rem!important;
    color: black !important;
    margin-top: -6rem !important;
    z-index: 4 !important;
    position: relative;
}

.about-header{
    font-size: $sub-header-size !important;
    font-weight: $super-header-weight;
}

.about-para{
    font-size: $normal-size !important;
    font-weight: $light-weight;
    line-height: 1.2;
}

.about-team{
    background-color: #EDEDED !important;
    height: 100% !important;
    padding: 10rem 0rem;
    margin-top: -4rem !important;
}

.about-team-row{
    padding: 4rem 6rem 2rem 6rem!important;
}

.about-team-col{
    text-align: center !important;
    position: relative !important;
    justify-content: center;
    // display: flex;
}

.about-team-img{
    width: 18rem !important;
    cursor: pointer !important;    
}


.padding-0{
    padding-right:0;
    padding-left:0;
}

.about-overlay{
    text-align: left !important;
    padding: 2rem;
    background-color: transparent !important;
    border: 2px solid $secondary-color !important;
    color: $secondary-color !important;
    width: 100% !important;

    // display: flex;
    // justify-content: center;
    // flex-direction: column;

    // position: absolute;
    // top: 0 !important;
    height: 100% !important;

    transition: 0.4s ease-in;
    // opacity: 0 !important;
}


.about-team-col:hover .about-overlay{
    opacity: 1 !important;
}

.desig-text{
    font-size: $misc-size !important;
    font-weight: $light-weight !important;
    font-style: italic !important;
}

.name-text{
    font-size: $normal-size !important;
    font-weight: $header-subheader-weight;
    line-height: 1.1;
    margin-top: -1rem !important;
}

.bio-text{
    font-weight: $light-weight !important;
    width: 100%;
    background-image: url('../../../../Global/Images/quotebg.svg') !important;
    background-repeat: no-repeat;
    padding: 1rem 0rem 0rem 1rem !important;
}

.middle-founder-col-text{
    font-weight: $light-weight !important;
    width: 100%;
}

.low-founder-para{
    width: 50% !important;
}

.founder-img{
    // height: 100% !important;
}

@media only screen and(max-width:768px) {
    .sec-row-about{
        margin-top: 0rem !important;
    }

    .founder-img{
        height: auto !important;
    }

    .low-founder-para{
        width: 90% !important;
    }

    .about-team-row {
        padding: 4rem 0rem 2rem 0rem !important;
    }

    .about-past{
        background-color: $primary-color !important;
        padding: 4rem 2rem!important;
        color: black !important;
        margin-top: 1rem !important;
        z-index: 4 !important;
        position: relative;
    }
    
    .about-para{
        font-size: $normal-size-mobile !important;
        font-weight: $light-weight;
        line-height: 1.2;
    }
    
    .about-team{
        background-color: #F8F8F8 !important;
        height: 100% !important;
        padding: 4rem 2rem;
        margin-top: 0rem !important;
    }

    .about-team-img{
        width: 100% !important;
        cursor: pointer !important;    
    }

    .bio-text{
        font-weight: $light-weight !important;
        width: 100%;
        font-size:0.8rem !important;
        background-image: url('../../../../Global/Images/quotebg.svg') !important;
        background-repeat: no-repeat;
        padding: 1rem 0rem 0rem 1rem !important;
    }

    .m-t-b{
        margin: 2rem 0rem !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .about-past{
        background-color: $primary-color !important;
        padding: 4rem 2rem!important;
        color: black !important;
        margin-top: -3rem !important;
        z-index: 4 !important;
        position: relative;
    }

    .founder-img{
        height: auto !important;
    }

     .m-t-b{
        margin: 2rem 0rem !important;
    }
    
    .about-team-row {
        padding: 4rem 2rem 2rem 2rem !important;
    }

    .about-team{
        background-color: #F8F8F8 !important;
        height: 100% !important;
        padding: 4rem 2rem;
        margin-top: -2rem !important;
    }

    .team-info-col{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .about-team-img{
        width: 14rem !important;
        cursor: pointer !important;    
    }

    .desig-text{
        font-size: 0.8rem !important;
        font-weight: $light-weight !important;
        font-style: italic !important;
    }
    
    .name-text{
        font-size: 1.1rem !important;
        font-weight: $header-subheader-weight;
        line-height: 1.1;
        margin-top: -1rem !important;
    }
    
    .bio-text{
        font-weight: $light-weight !important;
        width: 100%;
        font-size:0.8rem !important;
        background-image: url('../../../../Global/Images/quotebg.svg') !important;
        background-repeat: no-repeat;
        padding: 1rem 0rem 0rem 1rem !important;
    }

    .about-overlay{
        text-align: left !important;
        padding: 1rem;
        width:100% !important;

        background-color: transparent !important;
        border: 2px solid $secondary-color !important;
        color: $secondary-color !important;
    
        // position: absolute;
        // top: 0 !important;
        height: 100% !important;
    
        transition: 0.4s ease-in;
        // opacity: 0 !important;
    }
}