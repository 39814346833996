@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

//typography
$font-family: 'Roboto', sans-serif;

$super-header-weight: 900;
$header-subheader-weight: 500;
$regular-weight: 400;
$light-weight: 300;

$large-header-size: 5.5rem;
$header-size: 4rem;
$sub-header-size: 2rem;
$normal-size: 1.5rem;
$para-size: 1rem;
$misc-size: 1rem; 

$large-header-size-mobile: 3rem;
$header-size-mobile: 1.8rem;
$sub-header-size-mobile: 1.4rem;
$normal-size-mobile: 1.1rem;
$para-size-mobile: 0.8rem;
$misc-size-mobile: 0.8rem; 



//color scheme
$primary-color: #FBAF42;
$secondary-color: #452610;
$tertiary-color: #FBAF42;
$fourth-color: #FDE7AD;
$white-color: #Fff;

body{
    font-family: $font-family !important;
    overflow-x: hidden !important;
    height: 100vh !important;
}

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1300px;
}

.big-header{
    font-size: $large-header-size;
    font-weight:$super-header-weight !important;
    line-height: 1 !important;
}

.normal{
    font-size: $normal-size !important;
    font-weight: $header-subheader-weight !important;
}

.para{
    font-size: $para-size;
    font-weight: $regular-weight !important;
    line-height: 1.4; 
}

// .desktop-only{
//     display: block !important;
// }


.box-btn{
    background-color: transparent !important;
    border: 2px solid $secondary-color !important;
    padding: 0.8rem 2rem !important;
    color: $secondary-color !important;
    font-size: $para-size !important;
    font-family: $header-subheader-weight !important;
    transition: 0.3s ease-in-out !important;

    &:hover{
        background-color: $secondary-color !important;
        color: white !important;
    }
}

.bi.bi-search{
    font-size: $para-size !important;
    color: $secondary-color !important;
}

.row-margin{
    margin-left: -3rem !important;
}


@media only screen and(max-width:768px) {
    body{
        overflow-x: hidden !important;
        width: 100vw !important;
    }

    .row-margin{
        margin-left: 0rem !important;
    }

    .para{
        font-size: $para-size-mobile !important;
        font-weight: $regular-weight !important;
    }
    
    .big-header{
        font-size: $large-header-size-mobile;
        font-weight:$super-header-weight !important;
        line-height: 1.2;
    }

    .normal{
        font-size: $normal-size-mobile !important;
        font-weight: $header-subheader-weight !important;
    }

    .box-btn{
        background-color: transparent !important;
        border: 1px solid $secondary-color !important;
        padding: 0.4rem 1rem !important;
        color: $secondary-color !important;
        font-size: $para-size-mobile !important;
        font-family: $header-subheader-weight !important;
        transition: 0.3s ease-in-out !important;
    
        &:hover{
            background-color: $secondary-color !important;
            color: white !important;
        }
    }

}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .row-margin{
        margin-left: 0rem !important;
    }

    .big-header{
        font-size: $large-header-size-mobile;
        font-weight:$super-header-weight !important;
        line-height: 1.2;
    }
}